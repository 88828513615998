import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import ContactForm from "../components/contactForm";
import OurLocation from "../components/OurLocation";

import { contactUsFeaturedContent, contactUsHeroDetails } from "../constants";

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    query {
      featuredContentImages: allFile(
        filter: {
          name: {
            in: ["01-facebook-logo-v4", "02-instagram-logo", "03-linkedIn_logo"]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      heroImage: allFile(filter: { name: { in: "contact-hero-v2" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.99
            )
          }
        }
      }
    }
  `);

  const structuredData = (
		<script type="application/ld+json">
		  {JSON.stringify({
			"@context": "https://schema.org",
			"@type": "LocalBusiness",
      "url": "https://intact-electrical.co.uk/contact-us",
      "name": "Intact Electrical Ltd",
      "image": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "46 Nursery Rd",
        "addressLocality": "Taplow",
        "postalCode": "SL6 0JZ",
        "addressCountry": "GB",
        "addressRegion": "Maidenhead"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "01628 280077",
        "contactType": "Customer Support"
      }
		  })}
		</script>
	)

  const featuredContentImages = data.featuredContentImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = contactUsHeroDetails;

  return (
    <>
      <Seo       
        title="Contact us"
        description="Intact Electrical Ltd, contact us, Lets connect and grow together, Get in touch with us, We are here our location"
        post={false}
        metaRobots="nofollow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={contactUsFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <ContactForm />
      <OurLocation />
      
    </>
  );
};

export default ContactUs;
